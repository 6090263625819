import { AxiosResponse } from "axios";
import axios from "axios";
import Api from "@/services/api";
import MAxiosError from "@/exception/MAxiosError";
import { IParamHistoryPlayer } from "@/interfaces/IParamHistoryPlayer";
import {IParamCloseGame} from "@/interfaces/IParamCloseGame";

type TicketParams = {
  ticketId: string,
  securityCode: string,
}

export default class Game {
  static async launch(
    operatorUuid: string,
    casinoId: string,
    token: string
  ): Promise<AxiosResponse> {
    try {
      return Api().get(`launch/${token}`, {
        params: {
          operatorUuid,
          casinoId,
        },
      });
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async getResults(gameUuid: string): Promise<AxiosResponse> {
    try {
      return Api().get(`launch/${gameUuid}/show-results/`, {
        params: {
          gameUuid,
        },
      });
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async getHistoryPlayer(params: IParamHistoryPlayer, userIsAgent: boolean): Promise<AxiosResponse> {
    const url = userIsAgent ? 'round/history-player-ticket/' : 'round/history-player/'
    try {
      return Api().get(url, {
        params
      });
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async notifyCloseGame(params: IParamCloseGame): Promise<AxiosResponse> {
    try {
      console.log("URL BASE",params.baseUrl);      
      return axios.post(`${params.baseUrl}/launch/show-lobby-event`, {userId:params.sessionID},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            "Access-Control-Allow-Origin": '*',
          }
      });
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async cancelTicket(params: TicketParams): Promise<AxiosResponse> {
    const { ticketId, securityCode } = params
    const url = `tickets/${ticketId}/cancel`
    try {
      return Api().put(url, {
        securityCode
      });
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async payTicket(params: TicketParams): Promise<AxiosResponse> {
    const { ticketId, securityCode } = params
    const url = `tickets/${ticketId}/pay`
    try {
      return Api().put(url, {
        securityCode
      });
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
}
